<template>
    <svg
        viewBox="0 0 50 50"
        stroke="#FF690C"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="0"
            cy="0"
            r="0"
            stroke="#FF690C"
            fill-opacity="0.1"
        />
        <path
            stroke-width="0"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M22 30L25 27.06C24.61 27.02 24.32 27 24 27C21.33 27 16 28.34 16 31V33H25L22 30ZM24 25C26.21 25 28 23.21 28 21C28 18.79 26.21 17 24 17C21.79 17 20 18.79 20 21C20 23.21 21.79 25 24 25Z"
            stroke="#FF690C"
            fill="#FF690C"
        />
        <path
            stroke-width="0"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M28.47 33.5L25 30L26.4 28.59L28.47 30.67L33.6 25.5L35 26.91L28.47 33.5Z"
            stroke="#FF690C"
            fill="#FF690C"
        />
    </svg>
</template>

<script>
export default {
    name: 'NotificationOrderIdAssigned'
}
</script>
<style scoped lang='scss'>
svg path, svg circle {
    stroke: $main-color;
    fill: $main-color;
}
</style>
